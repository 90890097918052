var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "8", if: "host-profile" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  attrs: {
                    titleTranslationKey: "components.profile.HostProfile.title",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save,
                    showDiscardButton: true
                  },
                  on: { discardChanges: _vm.onDiscardChanges }
                },
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "ValidatedInput",
                            _vm._g(
                              {
                                attrs: {
                                  translationKey: "hostProfile.displayName",
                                  field: _vm.$v.hostProfile.displayName,
                                  value: _vm.$v.hostProfile.displayName.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile.displayName,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      ),
                      _c("CCol", { attrs: { md: "6" } }),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "ValidatedInput",
                            _vm._g(
                              {
                                attrs: {
                                  translationKey: "hostProfile.firstName",
                                  field: _vm.$v.hostProfile.firstName,
                                  value: _vm.$v.hostProfile.firstName.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile.firstName,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "ValidatedInput",
                            _vm._g(
                              {
                                attrs: {
                                  translationKey: "hostProfile.lastName",
                                  field: _vm.$v.hostProfile.lastName,
                                  value: _vm.$v.hostProfile.lastName.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile.lastName,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "ValidatedInput",
                            _vm._g(
                              {
                                attrs: {
                                  translationKey: "hostProfile.phoneNumber",
                                  field: _vm.$v.hostProfile.phoneNumber,
                                  value: _vm.$v.hostProfile.phoneNumber.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile.phoneNumber,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "ValidatedInput",
                            _vm._g(
                              {
                                attrs: {
                                  translationKey: "hostProfile.companyName",
                                  field: _vm.$v.hostProfile.companyName,
                                  value: _vm.$v.hostProfile.companyName.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile.companyName,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedSelect", {
                            attrs: {
                              field: _vm.$v.hostProfile.companyLegalType,
                              options: _vm.companyLegalTypeOptions,
                              value: _vm.$v.hostProfile.companyLegalType.$model,
                              translationKey: "playroom.companyLegalType"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.hostProfile.companyLegalType,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.isCompanyLegalNameDisabled
                        ? _c(
                            "CCol",
                            { attrs: { md: "12" } },
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.hostProfile.companyLegalName,
                                  value:
                                    _vm.$v.hostProfile.companyLegalName.$model,
                                  translationKey: "playroom.companyLegalName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile.companyLegalName,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "AddressForm",
                            _vm._g(
                              {
                                attrs: {
                                  field: _vm.$v.hostProfile,
                                  value: _vm.$v.hostProfile.$model
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.hostProfile,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("CCol", { attrs: { md: "4" } })
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          icon: "cilCheck",
          text: _vm.$t(
            "components.profile.HostProfile.notificationMsg.success"
          ),
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function($event) {
            _vm.showSuccessNotification = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }